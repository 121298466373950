<div
  class="navbar h-full"
  [class.mobile]="(breakpoint$ | async) !== 'desktop'"
  [navFlyover]="flyover"
  #flyoverdirective="navFlyover"
>
  <div class="nav-container">
    <ng-container *ngFor="let item of navItems">
      <navbar-item
        [item]="item"
        *ngIf="
          item.position === 'left' &&
          item.visibleAtBreakpoint?.includes(breakpoint$ | async) &&
          visible[item.key]
        "
        (click)="handleClick(item, $event)"
        [activeItem]="activeItem$ | async"
      >
        <ng-container *ngIf="item.children">
          <div
            class="w-full h-auto flex flex-col absolute mt-12 rounded-button bg-subtle-100 py-2 px-2 gap-1"
          >
            <ng-container
              *ngFor="let childItem of item.children; let i = index"
            >
              <navbar-item-child
                [item]="childItem"
                [i]="i"
                (click)="handleClick(childItem, $event)"
                *ngIf="
                  childItem.visibleAtBreakpoint?.includes(
                    breakpoint$ | async
                  ) && visible[childItem.key]
                "
              ></navbar-item-child>
            </ng-container>
          </div>
        </ng-container>
      </navbar-item>
    </ng-container>
  </div>
  <div class="nav-container">
    <ng-container *ngFor="let item of navItems">
      <navbar-item
        [item]="item"
        [activeItem]="activeItem$ | async"
        *ngIf="
          item.position === 'right' &&
          item.visibleAtBreakpoint?.includes(breakpoint$ | async) &&
          visible[item.key]
        "
        (click)="handleClick(item, $event)"
      >
        <ng-container *ngIf="item.children">
          <div
            class="w-full h-auto flex flex-col absolute mt-12 rounded-button bg-subtle-100 py-2 px-2 gap-1"
          >
            <ng-container
              *ngFor="let childItem of item.children; let i = index"
            >
              <navbar-item-child
                [item]="childItem"
                [i]="i"
                (click)="handleClick(childItem, $event)"
                *ngIf="
                  childItem.visibleAtBreakpoint?.includes(
                    breakpoint$ | async
                  ) && visible[childItem.key]
                "
              ></navbar-item-child>
            </ng-container>
          </div>
        </ng-container>
      </navbar-item>
    </ng-container>
  </div>

  <ng-template #flyover>
    <div
      class="bg-base text-base-content z-20 grid grid-flow-row gap-3 w-full p-6"
    >
      <button (click)="flyoverdirective.toggle()" class="place-self-end">
        <span class="material-symbols-rounded text-5xl text-subtle-300"
          >close</span
        >
      </button>
      <ng-container *ngFor="let item of navItems">
        <ng-container *ngIf="item.children; else regular">
          <ng-container *ngFor="let childItem of item.children">
            <navbar-item-burger
              [item]="childItem"
              (click)="
                flyoverdirective.toggle(); handleClick(childItem, $event)
              "
              *ngIf="
                !childItem.visibleAtBreakpoint?.includes(breakpoint$ | async) &&
                visible[childItem.key]
              "
            ></navbar-item-burger>
          </ng-container>
        </ng-container>
        <ng-template #regular>
          <navbar-item-burger
            [item]="item"
            (click)="flyoverdirective.toggle(); handleClick(item, $event)"
            *ngIf="
              !item.visibleAtBreakpoint?.includes(breakpoint$ | async) &&
              visible[item.key]
            "
          ></navbar-item-burger>
        </ng-template>
      </ng-container>
    </div>
  </ng-template>
</div>
