import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiOverlayTriggerModule } from "@paragondata/ngx-ui/modal";
import { AppNavbarComponent } from "./navbar.component";
import { NavOverlayDirective } from "./navbar-overlay.directive";
import { TranslocoModule } from "@ngneat/transloco";
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { AppNavbarItemComponent } from "./custom-controls/navbar-item.component";
import { AppNavbarItemBurgerComponent } from "./custom-controls/navbar-item-burger.component";
import { AppNavbarItemChildComponent } from "./custom-controls/navbar-item-child.component";

@NgModule({
    imports: [CommonModule, RouterModule, UiOverlayTriggerModule, TranslocoModule, CdkAccordionModule, AppNavbarItemComponent, AppNavbarItemBurgerComponent, AppNavbarItemChildComponent],
    exports: [AppNavbarComponent, NavOverlayDirective],
    declarations: [AppNavbarComponent, NavOverlayDirective]
  })
  export class AppNavbarModule { }
