import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Config } from '@paragondata/ngx-config';
import { authInterceptorFn } from '@core/auth';
import { ApiConfiguration } from '@swagger/generated/humanresources/api-configuration';

export function createConfigurationFactory(name: string) {
  return function (config: Config): { rootUrl: string } {
    return config.get(`@openapi/${name}`);
  };
}

@NgModule({
  providers: [
    {
      provide: ApiConfiguration,
      useFactory: createConfigurationFactory('humanresources'),
      deps: [Config],
    },
    provideHttpClient(
      withInterceptors([authInterceptorFn])
    )
  ],
})
export class OpenApiModule {}
