import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HrpContributorScheduleService } from '@swagger/services';
import { planGroupActions, planGroupFilterActions } from './plan-group.actions';
import { catchError, debounceTime, map, switchMap } from 'rxjs';
import { DateService } from '@shared/utils';
import { Filter, IFilter } from '@paragondata/ngx-ui/filter';

@Injectable()
export class PlanGroupEffects {
    private actions$ = inject(Actions);
    private api = inject(HrpContributorScheduleService);
    private dateService = inject(DateService);
  
    fetchSchedules$ = createEffect(() =>
      this.actions$.pipe(
        ofType(planGroupActions.request),
        debounceTime(1000),
        switchMap(({date, mode, filter, skip}) => {            
          let timespan: { start: Date; stop: Date };
          if (mode === 'month') {
            //für die Monatsansicht im Kalender müssen komplette Wochen geladen werden, nicht nur exakt der Monat
            timespan = this.dateService.getExtendedMonth({
              selectedDate: date,
            });
          } else {
            timespan = this.dateService.getTimespan({
              selectedDate: date,
              mode: mode,
            });
          }
          return this.api.employeeScheduleQueryEmployeeSchedules(timespan.start, timespan.stop, Filter.create(filter as IFilter)?.getQueryToken(), skip, 20).pipe(
              map(
                (response: any) => {
                  return planGroupActions.success({ response: response?.result || [] });
                }
              ), catchError((error) => [planGroupActions.failure({ error })])
            );
        })
      )
    );

    fetchFilter$ = createEffect(() =>    
        this.actions$.pipe(
          ofType(planGroupFilterActions.request),
          debounceTime(1000),
          switchMap(() => {
            return this.api
              .employeeScheduleQuerySettings().pipe(map(response => {
                return planGroupFilterActions.success({ filter: response.result });
              }, catchError((error) => {
                return [planGroupFilterActions.failure({ error })]
              }),
            ));
          })
        )
    );
  
}