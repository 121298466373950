
import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

@Directive({selector: '[navFlyover]', exportAs: 'navFlyover'})
export class NavOverlayDirective implements OnInit{

  @Input('navFlyover')
  template: any;

  private overlayRef: OverlayRef;
  private scrollStrategy: ScrollStrategy;
  private clickoutSub: Subscription;
  private embeddedViewRef: EmbeddedViewRef<any> | undefined = undefined;

  private _isOpen: boolean = false;

  get isOpen(){
    return this._isOpen;
  }
  set isOpen(value: boolean){
    this._isOpen = value;
  }

  constructor( private overlay: Overlay, private readonly sso: ScrollStrategyOptions, private viewContainerRef: ViewContainerRef, private cdr: ChangeDetectorRef){
    this.scrollStrategy = this.sso.noop();
  }

  ngOnInit(): void {
    this.createOverlay();
  }


  createOverlay(){
    this.overlayRef = this.overlay.create({
      //height: 500,
      width: '100%',
      positionStrategy: this.overlay
       .position()
       .global()
       .centerVertically()
       .top(),
      scrollStrategy: this.scrollStrategy,
      hasBackdrop: true,
      backdropClass: ['cdk-overlay-dark-backdrop'],
    });
  }

  toggle(){
    if(this.isOpen){
      this.close();
      this.isOpen = false;
    }else{
      this.open();
      this.isOpen = true;
    }
  }

  open(){
    const templatePortal = new TemplatePortal(this.template, this.viewContainerRef);
     this.embeddedViewRef = this.overlayRef.attach(templatePortal);

    this.clickoutSub = this.overlayRef.backdropClick().subscribe((click) => {
      this.toggle();
    });
  }

  close() {
    this.overlayRef.detach();
    this.cdr.markForCheck();
    this.clickoutSub.unsubscribe();
    this.embeddedViewRef?.destroy();
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.clickoutSub?.unsubscribe();
    this.overlayRef?.dispose();
    this.embeddedViewRef?.destroy();
  }

}
