import { LOCALE_ID, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import moment from 'moment';
import 'moment/locale/de';

let locale = localStorage.getItem('locale');

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeEn, 'en-US', localeEnExtra);

if (!locale) {
  // get main language from browser
  const browserLang = navigator.language;
  const lang = browserLang.match(/de/) ? 'de-DE' : 'en-US';
  localStorage.setItem('locale', lang);
  locale = lang;

  moment.locale(lang);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([{ provide: LOCALE_ID, useValue: locale }])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
