// notification.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private itemClickedSubject = new Subject<void>();

  itemClicked$ = this.itemClickedSubject.asObservable();

  notifyItemClicked() {
    this.itemClickedSubject.next();
  }
}
