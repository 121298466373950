<a
  class="nav-item child h-12 icon-bold w-full"

  [class.nav-item-mobile]="(breakpoint$ | async) !== 'desktop' && !item.rounded"
  [class.nav-item-desktop]="
    (breakpoint$ | async) === 'desktop' && !item.rounded
  "
  routerLinkActive="active"
  [routerLink]="item.routerLink"
  [queryParams]="item.queryParams"
  (click)="childClicked(item)"
>
  <span class="material-symbols-rounded mr-2">{{ item.icon }}</span>
  {{ item.title | transloco }}
</a>

<!-- absolute -->
<!-- [style.margin-top.px]="i * 48" -->
