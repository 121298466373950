import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators"
import { AppService } from "@core/services";
import * as AppActions from './app.actions';

@Injectable()
export class AppEffects {
  setLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.setLanguage),
    tap((action: any) => {
      this.appService.setLanguage(action.language);
    })),
    {dispatch: false}
  );

  setTheme$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.setTheme),
    tap(async (action: any) => {
        this.appService.setTheme(action.theme);
    })),
    {dispatch: false}
  );

  setState$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.setState),
    tap((action: any) => {
      this.appService.setState(action.state)
    })),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private appService: AppService) {}
}
