import { OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { NavbarItem } from '@core/defs';
import { RouterModule } from '@angular/router';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NavbarService } from '@core/services';
import { ResponsiveService } from '@core/services';
import { AppNotificationsComponent } from '@shared/ui';

@Component({
  selector: 'navbar-item-child',
  templateUrl: 'navbar-item-child.component.html',
  styleUrls: ['navbar-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    AppNotificationsComponent,
    CdkAccordionModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavbarItemChildComponent implements OnInit {
  @Input() item: NavbarItem = new NavbarItem();
  @Input() i: number;

  breakpoint$ = this.responsive.breakpoint$;

  constructor(
    private responsive: ResponsiveService,
    private navbar: NavbarService
  ) {}

  ngOnInit(): void {}

  childClicked(item: NavbarItem) {
    this.navbar.notifyItemClicked();
  }
}
