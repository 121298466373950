import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { AppShellModule } from './shell/shell.module';
import { SessionService } from '@core/auth';
import { AuthErrorComponent } from '@core/auth';
import { PageErrorComponent, PageNotFoundComponent } from '@shared/ui/error';

export const isAuthenticatedGuard: CanActivateFn  = (_: ActivatedRouteSnapshot, __: RouterStateSnapshot) => {
  const session = inject(SessionService);
  if (!session.isLoggedIn) {
    session.login();
    return false;
  }

  if (!session.isMitarbeiter) {
    inject(Router).navigate(['/auth-error'], { queryParams: { code: 'unauthorized' }});
    return false;
  }
  return true;
};
export const canActivateAdministration: CanActivateFn = (_: ActivatedRouteSnapshot, __: RouterStateSnapshot) => inject(SessionService).isAdministrator;

const routes: Routes = [
  {path: 'login', children: [
    { path: '**', redirectTo: 'kalender', pathMatch: 'full' },
  ]},
  {path: 'auth-error', component: AuthErrorComponent},
  {path: '', component: ShellComponent, canActivate: [isAuthenticatedGuard], children: [
    {path: 'kalender', loadChildren: () => import('@features/calendar/pages').then((m) => m.PagePlanModule)},
    {path: 'dashboard', loadChildren: () => import('@features/dashboard/pages').then((m) => m.PageDashboardModule)},
    {path: 'admin', loadChildren: () => import('@features/administration/pages').then((m) => m.routes), canActivate: [canActivateAdministration]},
    { path: 'messages', loadChildren: () => import('@features/messages/pages').then((m) => m.routes) },
    {path: 'mitarbeiter', loadChildren: () => import('@features/users/pages').then((m) => m.PageUsersModule) },
    {path: 'account', loadChildren: () => import('@features/account/pages').then((m) => m.PageAccountModule) },
    {path: 'planning', loadChildren: () => import('@features/planning/pages').then((m) => m.PlanningPageModule) },
    {path: '', redirectTo: 'kalender', pathMatch: 'full'},
    {path: 'error/:message/:redirectUrl', pathMatch: 'full',  component: PageErrorComponent},
    {path: '**', pathMatch: 'full',  component: PageNotFoundComponent},
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }), AppShellModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
