import { createReducer, on } from '@ngrx/store';
import { planGroupAdapter, planGroupInitialState } from './plan-group.state';
import { planGroupActions, planGroupFilterActions } from './plan-group.actions';

export const planGroupReducer = createReducer(
  planGroupInitialState,  
  on(planGroupActions.request, (state) => ({ ...state })),
  on(planGroupActions.success, (state, { response }) => {
    let nextState = planGroupAdapter.removeAll(state);
    nextState = planGroupAdapter.addMany(response, nextState);
    return { ...nextState, error: null };
  }),
  on(planGroupActions.failure, (state, { error }) => ({
    ...state, error
  })),
  on(planGroupActions.setEntities, (state, { entities }) => {
    let nextState = planGroupAdapter.removeAll(state);
    nextState = planGroupAdapter.addMany(entities, nextState);
    return { ...nextState };
  }),
  on(planGroupActions.clear, (state) => ({ ...planGroupInitialState, defaultFilter: state.defaultFilter })),
  on(planGroupFilterActions.setFilter, (state, { filter }) => ({ ...state, filter })),
  on(planGroupFilterActions.request, (state) => ({ ...state })),
  on(planGroupFilterActions.success, (state, { filter }) => ({
    ...state,
    defaultFilter: filter,
    filter
  })),
  on(planGroupFilterActions.failure, (state, { error }) => ({
    ...state, filter: {}
  }),
));
