import { Component, DestroyRef, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Config } from '@paragondata/ngx-config';
import { AppStore } from './store/app/app.store';
import { firstValueFrom} from 'rxjs';
import { OrganizationalUnitService } from '@features/administration/business';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(private title: Title,
     private config: Config,
     private store: AppStore,
     private organizationalUnitService: OrganizationalUnitService,
     private destroyRef: DestroyRef
    ) {
    this.setPageLanguage();
    this.setPageTheme();
    this.setPageTitle();
    this.organizationalUnitService.organizationalUnits$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  setPageTitle() {
    const title = this.config.get('title');
    if (!title) {
      throw new Error('title is missing in config');
    }
    this.title.setTitle(title);
  }

  async setPageLanguage() {
    try {
      const language = await firstValueFrom(this.store.language$) || 'de';
      this.store.setLanguage(language);
    } catch (err) {
      console.error('Error setting language', err);
    }
  }

  async setPageTheme() {
    try {
      const theme = await firstValueFrom(this.store.theme$) || 'hrp-light';
      this.store.setTheme(theme);
    } catch (err) {
      console.error('Error setting theme', err);
    }
  }
}
