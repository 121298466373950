import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@core/auth';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'hrp-auth-error',
    template: `
        <div class="grid grid-flow-row gap-4 items-center mt-8 p-4" *transloco="let t">
            <h1 class="isa-text-h3">{{t('auth-error-title')}}</h1>
            <p class="isa-text-p1">
                <ng-container *ngIf="!!errorCode; else defaultError">{{t('auth-error-code-'+errorCode)}}</ng-container>
                <ng-template #defaultError>{{t('auth-error-default')}}</ng-template>
            </p>
            <div>
                <button class="primary" (click)="logout()">
                    {{t('auth-error-logout')}}
                </button>
            </div>
        </div>
    `,
    standalone: true,
    imports: [CommonModule, TranslocoModule]
})

export class AuthErrorComponent implements OnInit {
    errorCode: string;

    constructor(private activatedRoute: ActivatedRoute, private auth: AuthService) { }

    ngOnInit() {
        console.log(this.activatedRoute.snapshot.queryParams);
        this.errorCode = this.activatedRoute.snapshot.queryParams.code;
    }

    logout() {
        try {
            this.auth.logout();
        } catch (err) {
            console.error(err);
        }
    }
}