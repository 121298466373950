import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HrpMyScheduleService } from '@swagger/services';
import {
  planActions,
  workingSummaryActions,
  workingSummaryMonthActions,
} from './plan.actions';
import { catchError, debounceTime, map, switchMap } from 'rxjs';
import { DateService } from '@shared/utils';
import {
  AggregationTimespan,
  ResponseArgsOfContributorScheduleDto,
} from '@swagger/generated/humanresources';
import { MyAccountBusinessService } from '@features/calendar/business';
import moment from 'moment';

@Injectable()
export class PlanEffects {
  private actions$ = inject(Actions);
  private api = inject(HrpMyScheduleService);
  private accountApi = inject(MyAccountBusinessService);
  private dateService = inject(DateService);

  fetchSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planActions.request),
      debounceTime(1000),
      switchMap(({ date, mode }) => {
        let timespan: { start: Date; stop: Date };
        if (mode === 'month') {
          //für die Monatsansicht im Kalender müssen komplette Wochen geladen werden, nicht nur exakt der Monat
          timespan = this.dateService.getExtendedMonth({
            selectedDate: date,
          });
        } else {
          timespan = this.dateService.getTimespan({
            selectedDate: date,
            mode: mode,
          });
        }
        return this.api
          .myScheduleGetMyScheduleByTime(timespan.start, timespan.stop)
          .pipe(
            map((response: ResponseArgsOfContributorScheduleDto) => {
              return planActions.success({ response: response.result });
            }),
            catchError((error) => [planActions.failure({ error })])
          );
      })
    )
  );

  fetchWorkingSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workingSummaryActions.request),
      debounceTime(1000),
      switchMap(({ scheduleUId, date, mode, aggregatedBy }) => {
        let timespan: { start: Date; stop: Date };
        if (mode === 'month') {
          // Ticket 2052
          // Für die aufgeklappte Monatsansicht im Kalender müssen komplette Wochen geladen werden, damit alle Kalenderwochen der Anzeige abgebildet werden können
          // -> Bsp. September 2024 hat in der Anzeige 6 Kalenderwochen, die alle geladen werden müssen
          // Der Exakte Monat wird durch einen separaten Effect geladen für die Anzeige der kumulierten Stunden und genommenen Urlaubstage
          timespan = this.dateService.getExtendedMonth({
            selectedDate: date,
          });
        } else {
          timespan = this.dateService.getTimespan({
            selectedDate: date,
            mode: mode,
          });
        }
        return this.accountApi
          .getMyAccountWorkingSummary({
            scheduleUId,
            start:
              aggregatedBy === AggregationTimespan.Month
                ? moment(timespan.start).add(1, 'days').toDate() // TODO: remove this workaround when the API is fixed - Sollte eigentlich 12 Monate zurückgeben, gibt aber den letzten Monat vom Vorjahr auch zurück
                : timespan.start,
            stop: timespan.stop,
            aggregatedBy,
          })
          .pipe(
            map(
              (response) => {
                return workingSummaryActions.success({
                  workingSummaryValues: response.result,
                });
              },
              catchError((error) => {
                return [workingSummaryActions.failure({ error })];
              })
            )
          );
      })
    )
  );

  fetchWorkingSummaryMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workingSummaryMonthActions.request),
      debounceTime(1000),
      switchMap(({ scheduleUId, date }) => {
        let timespan: { start: Date; stop: Date };

        timespan = this.dateService.getTimespan({
          selectedDate: date,
          mode: 'month',
        });

        return this.accountApi
          .getMyAccountWorkingSummary({
            scheduleUId,
            start: timespan.start,
            stop: moment(timespan.stop).subtract(1, 'day').toDate(),
            aggregatedBy: AggregationTimespan.Month,
          })
          .pipe(
            map(
              (response) => {
                return workingSummaryMonthActions.success({
                  workingSummaryMonthValues: response?.result?.find(
                    (_) => true
                  ),
                });
              },
              catchError((error) => {
                return [workingSummaryMonthActions.failure({ error })];
              })
            )
          );
      })
    )
  );
}
