<ng-container *ngFor="let child of item.children || [item]">
  <a
    class="nav-item-overlay h-12 px-8 isa-text-p1-bold icon-bold"
    [routerLink]="child.routerLink"
    [queryParams]="child.queryParams"
    routerLinkActive="active"
  >
    <span class="material-symbols-rounded text-[32px]">{{ child.icon }}</span>
    {{ child.key | transloco }}
  </a>
</ng-container>
