import { CommonModule, DatePipe } from '@angular/common';
import { APP_INITIALIZER, NgModule, inject, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideEffectsManager } from '@ngneat/effects-ng';
import { ParModalModule } from '@paragondata/ngx-ui/modal';
import {
  Config,
  ConfigModule,
  JsonConfigLoader,
} from '@paragondata/ngx-config';
import { OAuthModule } from 'angular-oauth2-oidc';

import { GraphQLModule } from './graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStoreModule } from './store/store.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { DialogModule } from '@angular/cdk/dialog';
import { SnackbarModule } from '@paragondata/ngx-ui/snackbar';
import { OpenApiModule } from './open-api.module';
import { AuthService, AuthDevService, authInterceptorFn } from '@core/auth';
import { environment } from 'src/environments/environment';
import { TranslocoService, provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './providers/transloco-http-loader';
import { provideDynamicFormRoot } from 'src/shared/dynamic-form/dynamic-form.provider';
import { firstValueFrom } from 'rxjs';

export function initializeApp(
  config: Config,
  auth: AuthService,
  authDevService: AuthDevService,
  transloco: TranslocoService
): () => Promise<void> {
  return async () => {
    await config.init();

    if (!environment.production && environment.useSilentAuth) {
      if (environment.devToken) {
        authDevService.setDevToken(environment.devToken);
      } else {
        authDevService.devLogin();
      }
      return;
    }
    await auth.init(config.get('auth'));

    await firstValueFrom(transloco.selectTranslate('')); // trigger to load translations
  };
}
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppStoreModule,
    ReactiveFormsModule,

    DialogModule,
    ConfigModule.forRoot({
      useConfigLoader: JsonConfigLoader,
      jsonConfigLoaderUrl: '/config/config.json',
    }),
    OpenApiModule,
    OAuthModule.forRoot(),
    GraphQLModule,

    ParModalModule,
    SnackbarModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Config, AuthService, AuthDevService, TranslocoService],
    },
    AppComponent,
    provideHttpClient(),
    provideEffectsManager(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: 'de',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: DateAdapter,
      useClass: DateAdapter,
    },
    DatePipe,
    provideDynamicFormRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
