import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { map } from 'rxjs';

@Component({
  selector: 'page-error',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  host: { class: 'flex flex-col items-center justify-center w-full h-[75%]' },
  styles: ['pre { word-break: break-word; }'],
  template: `
    <div class="max-w-[75vw] px-8 py-2">
      <h1 class="flex flex-row gap-4 items-center justify-center hrp-text-h24-emph my-4">
        {{ "error.page-error" | transloco }}
        <span class="material-symbols-rounded">sentiment_dissatisfied</span>
      </h1>

      <div class="grid grid-flow-col gap-2 bg-hrp-error-100 p-2 w-[300px]">
        <span class="text-hrp-error-700 material-symbols-rounded">info</span>
        <pre *ngIf="message$ | async; let message" class="text-hrp-error-900 whitespace-pre-wrap">{{ message }}</pre>
      </div>
      

      <div class="flex flex-row justify-center gap-2 mt-8">
        <button *ngIf="redirectUrl$ | async; let redirectUrl" class="primary accent" (click)="router.navigate([redirectUrl])">{{ "error.retry" | transloco }}</button>
      </div>
    </div>
  `,
})
export class PageErrorComponent {
  message$ = this.activatedRoute.params.pipe(map((params) => params.message));
  redirectUrl$ = this.activatedRoute.params.pipe(map((params) => params.redirectUrl));

  constructor(private activatedRoute: ActivatedRoute, public router: Router) {}
}
