import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { AuthService } from '@core/auth';
import { Config } from '@paragondata/ngx-config';

export function apolloOptionsFactory(
  config: Config,
  auth: AuthService,
  httpLink: HttpLink
): ApolloClientOptions<any> {

  const uri = config.get('@graphql.uri');

  const basicContext = setContext(() => ({
    headers: { Accept: 'charset=utf-8' },
  }));

  const authContext = setContext(() => {
    return setAuthTokenInHeader(auth);
  });

  const errorHandler = onError(({ forward, graphQLErrors, operation }) => {
    if (
      graphQLErrors?.find((_) => true)?.extensions?.code ===
      'AUTH_NOT_AUTHENTICATED'
    ) {
      console.error('graphql: not authenticated error');
      authContext.request(operation, forward);
    }

    return forward(operation);
  });

  return {
    link: ApolloLink.from([
      errorHandler,
      basicContext,
      authContext,
      httpLink.create({ uri }),
    ]),
    cache: new InMemoryCache(),
  };
}

function setAuthTokenInHeader(auth: AuthService) {
  const token = auth.getToken();
  return token ? { headers: { Authorization: `bearer ${token}` } } : {};
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloOptionsFactory,
      deps: [Config, AuthService, HttpLink],
    },
  ],
})
export class GraphQLModule {}
