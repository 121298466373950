import { createReducer, on } from '@ngrx/store';
import { planAdapter, planInitialState } from './plan.state';
import {
  planActions,
  workingSummaryActions,
  workingSummaryMonthActions,
} from './plan.actions';

export const planReducer = createReducer(
  planInitialState,
  on(planActions.request, (state) => ({ ...state })),
  on(planActions.success, (state, { response }) => {
    let nextState = planAdapter.removeAll(state);
    nextState = planAdapter.addOne(response, nextState);
    return { ...nextState };
  }),
  on(planActions.failure, (state, { error }) => ({
    ...state,
  })),
  on(workingSummaryActions.request, (state) => ({ ...state })),
  on(workingSummaryActions.success, (state, { workingSummaryValues }) => ({
    ...state,
    workingSummaryValues,
  })),
  on(workingSummaryActions.failure, (state, { error }) => ({
    ...state,
    workingSummaryValues: [],
  })),
  on(workingSummaryMonthActions.request, (state) => ({ ...state })),
  on(
    workingSummaryMonthActions.success,
    (state, { workingSummaryMonthValues }) => ({
      ...state,
      workingSummaryMonthValues,
    })
  ),
  on(workingSummaryMonthActions.failure, (state, { error }) => ({
    ...state,
    workingSummaryMonthValues: undefined,
  }))
);
