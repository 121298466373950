import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-process',
    templateUrl: 'process.component.html',
    styleUrl: 'process.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})

export class ProcessComponent implements OnInit {
    @Input() process: { id: number, name: string, active?: boolean }
    constructor() { }

    ngOnInit() { }

    closeProces() {
        // -> service call close process with this.process
    }
}