import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthDevService {
    private client_id = 'hrp-app';
    private user_name = `HUG%7C${localStorage.getItem('dev_username')}` || 'hrpdevadm03';
    private user_password = localStorage.getItem('dev_userpassword') || 'pp3$dev_1903';
    private scope = 'openid+profile+cmf_user+hrp-webapi';

    /**
     * Auth Service um CORS Probleme beim Test auf mobilen Geräten zu umgehen.
     * @see AuthService.getToken() für die Prüfung des Tokens
     */
    constructor(private http: HttpClient) {}

    async devLogin() {
        const res = await firstValueFrom(this.http.post(
          "https://sso-test.paragon-data.de/connect/token",
          `grant_type=password&client_id=${this.client_id}&username=${this.user_name}&password=${this.user_password}&scope=${this.scope}`,
          { headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Basic aHJwLWFwcDo1MTM2NDJjYTRjZWU0NTAxYWY5ODA5ODVmN2IzNGE2NQ==",
          }, }
        ));
        const token = res['access_token'];
        this.setDevToken(token);
      }

    setDevToken(token: string) {
        localStorage.setItem('dev_token', token);
        console.log('DEV TOKEN', token);
    }
}