import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { AuthService } from "@core/auth";


export const authInterceptorFn: HttpInterceptorFn = (req, next) => { 
  const auth = inject(AuthService);
  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${auth.getToken()}`,
    },
  });
  return next(req);
};