import { ContributorAccountService } from '@swagger/generated/humanresources/services';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EmployeeAccountBusinessService {
  constructor(
    private readonly _employeeAccountService: ContributorAccountService
  ) {}

  getEmployeeAccountWorkingSummaryPerDay({
    employeeUId,
    scheduleUId,
    start,
    stop,
  }: {
    employeeUId: string;
    scheduleUId: string;
    start: Date;
    stop: Date;
  }) {
    return this._employeeAccountService.contributorAccountGetWorkingSummaryPerDay(
      {
        contributorUId: employeeUId,
        scheduleUId,
        body: {
          start: start.toISOString(),
          stop: stop.toISOString(),
        },
      }
    );
  }
}
